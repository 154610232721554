import './ProjectsGrid.css';
import ProjectCard from './ProjectCard';
import * as homepageStrings from '../Strings/HomepageStrings.js';
import * as paths from '../Strings/Paths';
import projectRocketCover from '../Images/Homepage/project_rocket_cover.png';
import projectCrudeCover from '../Images/Homepage/project_crude_cover.png';
import projectOyoCover from '../Images/Homepage/project_oyo_cover.png';
import projectComfortOSCover from '../Images/Homepage/project_comfort_os_cover.png';
import projectHometownHeroesCommunityCover from '../Images/Homepage/project_hometown_heroes_community_cover.png';

function ProjectsGrid() {




    return (
        <div className='grid grid-cols-1 gap-16 md:grid-cols-2'>

            <ProjectCard
                image={projectOyoCover}
                alt_text={homepageStrings.PROJECT_OYO_ALT}
                tag1_label={homepageStrings.PROJECT_OYO_TAG1}
                // tag2_label={homepageStrings.PROJECT_OYO_TAG2}
                tag3_label={homepageStrings.PROJECT_OYO_TAG3}
                title={homepageStrings.PROJECT_OYO_TITLE}
                subtitle={homepageStrings.PROJECT_OYO_SUBTITLE}
                path={paths.PROJECT_OYO_PATH}
                size='large'
            />

            <ProjectCard
                image={projectHometownHeroesCommunityCover}
                alt_text={homepageStrings.PROJECT_HOMETOWN_HEROES_COMMUNITY_ALT}
                tag1_label={homepageStrings.PROJECT_HOMETOWN_HEROES_COMMUNITY_TAG1}
                tag2_label={homepageStrings.PROJECT_HOMETOWN_HEROES_COMMUNITY_TAG2}
                tag3_label={homepageStrings.PROJECT_HOMETOWN_HEROES_COMMUNITY_TAG3}
                title={homepageStrings.PROJECT_HOMETOWN_HEROES_COMMUNITY_TITLE}
                subtitle={homepageStrings.PROJECT_HOMETOWN_HEROES_COMMUNITY_SUBTITLE}
                path={paths.PROJECT_HOMETOWN_HEROES_PATH}
                size='large'
            />



            {/* <ProjectCard
                image={projectCrudeCover}
                alt_text={homepageStrings.PROJECT_CRUDE_ALT}
                tag1_label={homepageStrings.PROJECT_CRUDE_TAG1}
                tag2_label={homepageStrings.PROJECT_CRUDE_TAG2}
                title={homepageStrings.PROJECT_CRUDE_TITLE}
                subtitle={homepageStrings.PROJECT_CRUDE_SUBTITLE}
                path={paths.PROJECT_CRUDE_PATH}
                size='large'
            /> */}

            <ProjectCard
                image={projectRocketCover}
                alt_text={homepageStrings.PROJECT_ROCKET_ALT}
                tag1_label={homepageStrings.PROJECT_ROCKET_TAG1}
                // tag2_label={homepageStrings.PROJECT_ROCKET_TAG2}
                tag3_label={homepageStrings.PROJECT_ROCKET_TAG3}
                title={homepageStrings.PROJECT_ROCKET_TITLE}
                subtitle={homepageStrings.PROJECT_ROCKET_SUBTITLE}
                path={paths.PROJECT_ROCKET_CASE_STUDY_PATH}
                size='large'
            />

            {/* <ProjectCard
                image={projectComfortOSCover}
                alt_text={homepageStrings.PROJECT_COMFORT_OS_ALT}
                tag1_label={homepageStrings.PROJECT_COMFORT_OS_TAG1}
                tag2_label={homepageStrings.PROJECT_COMFORT_OS_TAG2}
                title={homepageStrings.PROJECT_COMFORT_OS_TITLE}
                subtitle={homepageStrings.PROJECT_COMFORT_OS_SUBTITLE}
                path={paths.PROJECT_COMFORT_OS_PATH}
                size='large'
            /> */}



        </div>
    );

}

export default ProjectsGrid;