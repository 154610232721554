import './CaseStudyBanner.css';

function CaseStudyBanner({title, subtitle, image, alt_text}) {
    if (!title) {
        title = '';
    }
    if (!subtitle) {
        subtitle = '';
    }
    if (!image) {
        image = '';
    }
    if (!alt_text) {
        alt_text = '';
    }
    return (
      <div className="case-study-banner-container">
        <div className='w-full'>
        <h1 className='h1 indigo'>{title}</h1>
        <h4 className='h4 dark'>{subtitle}</h4>
        </div>
        <img src={image} alt={alt_text} className='rounded-3xl'/>
      </div>
    );
  }
  
export default CaseStudyBanner;
  