import './Testimonial.css';

const Testimonial = ({ title, subtitle, name, position, image, alt_text }) => {

    return (



        <div className="testimonial-container-width flex flex-col gap-2 md:gap-4 rounded-3xl p-6 testimonial-container-border flex-shrink-0 snap-start">
            <h6 className="h6 dark">{title}</h6>
            <p className="body grey truncated-text">{subtitle}</p>

            <div className="flex items-center gap-2 md:gap-4 pt-2 md:pt-4 w-full">
                <img src={image} alt={alt_text} className="w-8 md:w-12 rounded-full"></img>
                <div>
                    <p className="body dark">{name}</p>
                    <p className='caption grey'>{position}</p>
                </div>
            </div>
        </div>



    );
}

export default Testimonial;