import Header from "../../Components/Header";
import CaseStudyBanner from "../../Components/CaseStudyBanner";
import OverviewSection from '../../Components/OverviewSection';
import * as hhDesignSystemStrings from '../../Strings/HometownHeroesDesignSystemStrings';
import '../CaseStudy.css';
import Footer from "../../Components/Footer";
import SectionHeader from "../../Components/SectionHeader";
import Metric from "../../Components/Metric";
import BulletPoint from "../../Components/BulletPoint";
import Picture from "../../Components/Picture";
import ScrollToTop from '../../Components/ScrollToTop';
import hhDesignSystemHero from '../../Images/HometownHeroesDesignSystem/hh_design_system_hero.png';
import hhDesignSystemResearch from '../../Images/HometownHeroesDesignSystem/hh_design_system_research.png';
import hhDesignSystemTokenNaming from '../../Images/HometownHeroesDesignSystem/hh_design_system_token_naming.png';
import hhDesignSystemTokenLevels from '../../Images/HometownHeroesDesignSystem/hh_design_system_token_levels.png';
import hhDesignSystemTokensInAction from '../../Images/HometownHeroesDesignSystem/hh_design_system_tokens_in_action.png';
import hhDesignSystemTokensInActionDarkMode from '../../Images/HometownHeroesDesignSystem/hh_design_system_tokens_in_action_dark_mode.png';
import hhDesignSytemSpecialCases from '../../Images/HometownHeroesDesignSystem/hh_design_system_special_cases.png';
import hhDesignSystemTokenModes from '../../Images/HometownHeroesDesignSystem/hh_design_system_token_modes.png';
import hhDesignSystemTokenModesButton from '../../Images/HometownHeroesDesignSystem/hh_design_system_token_modes_button.png';
import photoSanjana from '../../Images/HometownHeroesDesignSystem/photo_sanjana.png';
import photoUtkarsh from '../../Images/HometownHeroesDesignSystem/photo_utkarsh.png';
import Button from "../../Components/Button";
import UserQuote from "../../Components/UserQuote";
import { useRef } from 'react';


function HometownHeroesDesignSystem() {

  const finalDesignRef = useRef(null);


  const hometownHeroesDesignSystemOverviewItems = [
    {
      'title': hhDesignSystemStrings.HH_DESIGN_SYSTEM_OVERVIEW_TITLE1,
      'subtitle': hhDesignSystemStrings.HH_DESIGN_SYSTEM_OVERVIEW_SUBTITLE1
    },
    {
      'title': hhDesignSystemStrings.HH_DESIGN_SYSTEM_OVERVIEW_TITLE2,
      'subtitle': hhDesignSystemStrings.HH_DESIGN_SYSTEM_OVERVIEW_SUBTITLE2
    },
    {
      'title': hhDesignSystemStrings.HH_DESIGN_SYSTEM_OVERVIEW_TITLE3,
      'subtitle': hhDesignSystemStrings.HH_DESIGN_SYSTEM_OVERVIEW_SUBTITLE3
    },
    {
      'title': hhDesignSystemStrings.HH_DESIGN_SYSTEM_OVERVIEW_TITLE4,
      'subtitle': hhDesignSystemStrings.HH_DESIGN_SYSTEM_OVERVIEW_SUBTITLE4
    }
  ];






  return (
    <div>
      <Header />

      <div className="fade-in page-container">
        <div className="sections-container">

          <div className='container my-0 mx-auto hero-container'>
            <CaseStudyBanner
              title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_BANNER_TITLE}
              subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_BANNER_SUBTITLE}
              image={hhDesignSystemHero}
              alt_text={hhDesignSystemStrings.HH_DESIGN_SYSTEM_BANNER_ALT}
            />
            <OverviewSection
              item_list={hometownHeroesDesignSystemOverviewItems}
            />


          </div>


          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_OVERVIEW_TITLE}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
              <p className="body grey">{hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_OVERVIEW_CONTENT}</p>
            </div>
          </div>

          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_CONTRIBUTIONS_TITLE}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">


              <BulletPoint
                number={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_CONTRIBUTIONS_BULLET_NUMBER1}
                subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_CONTRIBUTIONS_BULLET_SUBTITLE1}
              />

              <BulletPoint
                number={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_CONTRIBUTIONS_BULLET_NUMBER2}
                subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_CONTRIBUTIONS_BULLET_SUBTITLE2}
              />

              <BulletPoint
                number={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_CONTRIBUTIONS_BULLET_NUMBER3}
                subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_CONTRIBUTIONS_BULLET_SUBTITLE3}
              />

            </div>
          </div>

          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_IMPACT_TITLE}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
              <BulletPoint
                number={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_IMPACT_BULLET_NUMBER1}
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_IMPACT_BULLET_TITLE1}
                subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_IMPACT_BULLET_SUBTITLE1}
              />
              <BulletPoint
                number={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_IMPACT_BULLET_NUMBER2}
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_IMPACT_BULLET_TITLE2}
                subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_IMPACT_BULLET_SUBTITLE2}
              />
              <BulletPoint
                number={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_IMPACT_BULLET_NUMBER3}
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_IMPACT_BULLET_TITLE3}
                subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_IMPACT_BULLET_SUBTITLE3}
              />
              <div className="w-full flex flex-col md:flex-row gap-4 md:gap-12 pl-4">
                <UserQuote
                  quote={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_IMPACT_TESTIMONIAL_QUOTE1}
                  name={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_IMPACT_TESTIMONIAL_NAME1}
                  role={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_IMPACT_TESTIMONIAL_ROLE1}
                  alt_text={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_IMPACT_TESTIMONIAL_ALT1}
                  image={photoSanjana}
                />
                <UserQuote
                  quote={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_IMPACT_TESTIMONIAL_QUOTE2}
                  name={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_IMPACT_TESTIMONIAL_NAME2}
                  role={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_IMPACT_TESTIMONIAL_ROLE2}
                  alt_text={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_IMPACT_TESTIMONIAL_ALT2}
                  image={photoUtkarsh}
                />
              </div>
            </div>
          </div>         


          <hr className="divider" />

          {/* <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={'comprehensive paleete'}
                label={'the palette'}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
              
            </div>
          </div> */}

          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_PROCESS_TITLE}
                type='wide'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
              <div className='pt-4 md:pt-0 md:text-end'>
                <Button
                  label={'Skip to final design'}
                  click_function={() => finalDesignRef.current?.scrollIntoView({ behavior: 'smooth' })}
                />
              </div>
            </div>
          </div>




          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_OBJECTIVES_TITLE}
                label={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_OBJECTIVES_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col md:flex-row gap-4 md:gap-8">
              <BulletPoint
                // number={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_OBJECTIVES_CONTENT_NUMBER1}
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_OBJECTIVES_CONTENT_TITLE1}
                subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_OBJECTIVES_CONTENT_SUBTITLE1}
              />
              <BulletPoint
                // number={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_OBJECTIVES_CONTENT_NUMBER2}
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_OBJECTIVES_CONTENT_TITLE2}
                subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_OBJECTIVES_CONTENT_SUBTITLE2}
              />
            </div>
          </div>

          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_RESEARCH_TITLE}
                label={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_RESEARCH_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
              <Picture
                image={hhDesignSystemResearch}
                alt_text={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_RESEARCH_ALT}
                rounded={true}
              />
              <BulletPoint
                number={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_RESEARCH_BULLET_NUMBER1}
                subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_RESEARCH_BULLET_SUBTITLE1}
              />
              <BulletPoint
                number={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_RESEARCH_BULLET_NUMBER2}
                subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_RESEARCH_BULLET_SUBTITLE2}
              />
              <BulletPoint
                number={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_RESEARCH_BULLET_NUMBER3}
                subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_RESEARCH_BULLET_SUBTITLE3}
              />
            </div>
          </div>


          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_CONSTRAINTS_TITLE}
                label={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_CONSTRAINTS_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col md:flex-row gap-4 md:gap-8">
              <BulletPoint
                // number={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_CONSTRAINTS_BULLET_NUMBER1}
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_CONSTRAINTS_BULLET_TITLE1}
                subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_CONSTRAINTS_BULLET_SUBTITLE1}
              />
              <BulletPoint
                // number={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_CONSTRAINTS_BULLET_NUMBER2}
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_CONSTRAINTS_BULLET_TITLE2}
                subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_CONSTRAINTS_BULLET_SUBTITLE2}
              />
            </div>
          </div>


          {/* <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_PRINCIPLES_TITLE}
                label={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_PRINCIPLES_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
              <BulletPoint
                number={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_PRINCIPLES_CONTENT_NUMBER1}
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_PRINCIPLES_CONTENT_TITLE1}
                subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_PRINCIPLES_CONTENT_SUBTITLE1}
              />
              <BulletPoint
                number={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_PRINCIPLES_CONTENT_NUMBER2}
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_PRINCIPLES_CONTENT_TITLE2}
                subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_PRINCIPLES_CONTENT_SUBTITLE2}
              />
            </div>
          </div> */}


          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_LEVELS_TITLE}
                label={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_LEVELS_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
              <Picture
                image={hhDesignSystemTokenLevels}
                alt_text={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_LEVELS_ALT}
                rounded={true}
              />
            </div>
          </div>


          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_STRUCTURE_TITLE}
                label={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_STRUCTURE_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
              <Picture
                image={hhDesignSystemTokenNaming}
                alt_text={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_STRUCTURE_ALT}
                rounded={true}
              />
              <BulletPoint
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_STRUCTURE_CONTENT_TITLE1}
                subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_STRUCTURE_CONTENT_SUBTITLE1}
              />
              <BulletPoint
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_STRUCTURE_CONTENT_TITLE2}
                subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_STRUCTURE_CONTENT_SUBTITLE2}
              />
              <BulletPoint
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_STRUCTURE_CONTENT_TITLE3}
                subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_STRUCTURE_CONTENT_SUBTITLE3}
              />
            </div>
          </div>

          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_SPECIAL_CASES_TITLE}
                label={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_SPECIAL_CASES_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
              <Picture
                image={hhDesignSytemSpecialCases}
                alt_text={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_SPECIAL_CASES_ALT}
                rounded={true}
              />
            </div>
          </div>

          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_DARK_MODE_TITLE}
                label={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_DARK_MODE_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
              <Picture
                image={hhDesignSystemTokenModes}
                alt_text={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_DARK_MODE_ALT}
                rounded={true}
              />
              <Picture
                image={hhDesignSystemTokenModesButton}
                alt_text={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_DARK_MODE_ALT2}
                rounded={true}
              />
            </div>
          </div>



          <div className="container w-full flex flex-col gap-4 md:gap-16" ref={finalDesignRef}>
            <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
              <div className="w-full">
                <SectionHeader
                  title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_TOKENS_IN_ACTION_TITLE}
                  label={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_TOKENS_IN_ACTION_LABEL}
                  type='short'
                />
              </div>
            </div>
            <div className="w-full px-4 md:px-16 flex flex-col gap-4 md:gap-16">
              <div className="w-full flex flex-col gap-4 md:gap-8">
                <h4 className="h4 dark">{hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_TOKENS_IN_ACTION_LIGHT_TITLE}</h4>
                <Picture
                  image={hhDesignSystemTokensInAction}
                  alt_text={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_TOKENS_IN_ACTION_LIGHT_ALT}
                  rounded={true}
                />
              </div>
              <div className="w-full flex flex-col gap-4 md:gap-8">
                <h4 className="h4 dark">{hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_TOKENS_IN_ACTION_DARK_TITLE}</h4>
                <Picture
                  image={hhDesignSystemTokensInActionDarkMode}
                  alt_text={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_TOKENS_IN_ACTION_DARK_ALT}
                  rounded={true}
                />
              </div>
            </div>
          </div>



          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_LEARNINGS_TITLE}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
              <div className="w-full flex flex-col gap-4 md:gap-8">
                <BulletPoint
                  number={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_LEARNINGS_NUMBER1}
                  title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_LEARNINGS_TITLE1}
                  subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_LEARNINGS_SUBTITLE1}
                />
                <BulletPoint
                  number={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_LEARNINGS_NUMBER2}
                  title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_LEARNINGS_TITLE2}
                  subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_LEARNINGS_SUBTITLE2}
                />
                <BulletPoint
                  number={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_LEARNINGS_NUMBER3}
                  title={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_LEARNINGS_TITLE3}
                  subtitle={hhDesignSystemStrings.HH_DESIGN_SYSTEM_SECTION_LEARNINGS_SUBTITLE3}
                />

              </div>

            </div>
          </div>









        </div>

        <Footer />

      </div>

      <ScrollToTop />
    </div>
  );
}

export default HometownHeroesDesignSystem;
