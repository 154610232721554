import '../CaseStudy.css';
import AboutSection from "../../Components/AboutSection";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { NAV_ABOUT_LABEL } from "../../Strings/GlobalStrings";
import ContentSection from '../../Components/ContentSection';
import iconBolt from '../../Icons/icon_bolt.svg';
import iconBulb from '../../Icons/icon_bulb.svg';
import iconGroup from '../../Icons/icon_group.svg';
import iconTrophy from '../../Icons/icon_trophy.svg';
import * as aboutStrings from '../../Strings/AboutStrings';
import photoMing from '../../Images/Testimonials/photo_ming.png';
import photoSaloni from '../../Images/Testimonials/photo_saloni.png';
import photoKevin from '../../Images/Testimonials/photo_kevin.png';
import photoJessica from '../../Images/Testimonials/photo_jessica.png';
import photoSamita from '../../Images/Testimonials/photo_samita.png';
import photoShaunak from '../../Images/Testimonials/photo_shaunak.png';
import photoMrunmai from '../../Images/Testimonials/photo_mrunmai.png';
import photoNatasha from '../../Images/Testimonials/photo_natasha.png';
import iconUser from '../../Icons/icon_user.svg';
import ProjectCard from '../../Components/ProjectCard';
import SectionHeader from '../../Components/SectionHeader';
import photoAdobeStudentAmbassador from '../../Images/About/photo_adobe_student_ambassador.png';
import photoCooking from '../../Images/About/photo_cooking.png';
import photoDriving from '../../Images/About/photo_driving.png';
import photoFormula1 from '../../Images/About/photo_formula1.png';
import photoMentorship from '../../Images/About/photo_mentorship.png';
import photoWriting from '../../Images/About/photo_writing.png';
import { CONNECT_MEDIUM } from '../../Strings/GlobalStrings';
import BulletPoint from '../../Components/BulletPoint';



const AboutPage = () => {

    return (
        <div>
            <Header selected_link_label={NAV_ABOUT_LABEL} />

            <div className="fade-in page-container">
                <div className="sections-container">

                    <AboutSection />


                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={aboutStrings.ABOUT_VALUES_TITLE}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-16">
                            <BulletPoint
                                emoji={'🎯'}
                                title={aboutStrings.ABOUT_VALUES_TITLE1}
                                subtitle={aboutStrings.ABOUT_VALUES_SUBTITLE1}
                            />
                            <BulletPoint
                                emoji={'🤝'}
                                title={aboutStrings.ABOUT_VALUES_TITLE2}
                                subtitle={aboutStrings.ABOUT_VALUES_SUBTITLE2}
                            />
                            <BulletPoint
                                emoji={'⏳'}
                                title={aboutStrings.ABOUT_VALUES_TITLE3}
                                subtitle={aboutStrings.ABOUT_VALUES_SUBTITLE3}
                            />
                            <BulletPoint
                                emoji={'🚀'}
                                title={aboutStrings.ABOUT_VALUES_TITLE4}
                                subtitle={aboutStrings.ABOUT_VALUES_SUBTITLE4}
                            />
                        </div>
                    </div>

                    

                    {/* <ContentSection
                        title={aboutStrings.ABOUT_FAQ_TITLE}
                        type='short'
                        content_array={[
                            {
                                'content_type': 'bullet_points',
                                'bullet_points': [
                                    {
                                        'title': aboutStrings.ABOUT_FAQ_BULLET_TITLE1,
                                        'subtitle': aboutStrings.ABOUT_FAQ_BULLET_SUBTITLE1
                                    },
                                    {
                                        'title': aboutStrings.ABOUT_FAQ_BULLET_TITLE2,
                                        'subtitle': aboutStrings.ABOUT_FAQ_BULLET_SUBTITLE2
                                    },
                                    {
                                        'title': aboutStrings.ABOUT_FAQ_BULLET_TITLE3,
                                        'subtitle': aboutStrings.ABOUT_FAQ_BULLET_SUBTITLE3
                                    },
                                    {
                                        'title': aboutStrings.ABOUT_FAQ_BULLET_TITLE4,
                                        'subtitle': aboutStrings.ABOUT_FAQ_BULLET_SUBTITLE4
                                    }
                                ]
                            }
                        ]}
                    /> */}



                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={aboutStrings.ABOUT_OUTSIDE_WORK_TITLE}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16">
                            <ProjectCard
                                image={photoDriving}
                                alt_text={aboutStrings.ABOUT_DRIVING_ALT}
                                title={aboutStrings.ABOUT_DRIVING_TITLE}
                                subtitle={aboutStrings.ABOUT_DRIVING_SUBTITLE}
                                path={''}
                            />

                            {/* <ProjectCard
                                image={photoCooking}
                                alt_text={aboutStrings.ABOUT_COOKING_ALT}
                                title={aboutStrings.ABOUT_COOKING_TITLE}
                                subtitle={aboutStrings.ABOUT_COOKING_SUBTITLE}
                                path={''}
                            /> */}
                            <ProjectCard
                                image={photoWriting}
                                alt_text={aboutStrings.ABOUT_WRITING_ALT}
                                title={aboutStrings.ABOUT_WRITING_TITLE}
                                subtitle={aboutStrings.ABOUT_WRITING_SUBTITLE}
                                path={CONNECT_MEDIUM}
                            />

                            <ProjectCard
                                image={photoFormula1}
                                alt_text={aboutStrings.ABOUT_FORMULA1_ALT}
                                title={aboutStrings.ABOUT_FORMULA1_TITLE}
                                subtitle={aboutStrings.ABOUT_FORMULA1_SUBTITLE}
                                path={''}
                            />
                            {/* <ProjectCard
                                image={photoAdobeStudentAmbassador}
                                alt_text={aboutStrings.ABOUT_ADOBE_STUDENT_AMBASSADOR_ALT}
                                title={aboutStrings.ABOUT_ADOBE_STUDENT_AMBASSADOR_TITLE}
                                subtitle={aboutStrings.ABOUT_ADOBE_STUDENT_AMBASSADOR_SUBTITLE}
                                path={''}
                            /> */}

                            <ProjectCard
                                image={photoMentorship}
                                alt_text={aboutStrings.ABOUT_MENTORSHIP_ALT}
                                title={aboutStrings.ABOUT_MENTORSHIP_TITLE}
                                subtitle={aboutStrings.ABOUT_MENTORSHIP_SUBTITLE}
                                path={''}
                            />

                        </div>
                    </div>


                    

                    {/* <ContentSection
                        title={aboutStrings.ABOUT_TESTIMONIALS_TITLE}
                        type='short'
                        content_array={[
                            {
                                'content_type': 'testimonials',
                                'testimonials_array': [
                                    {
                                        'title': aboutStrings.ABOUT_TESTIMONIAL_TITLE1,
                                        'subtitle': aboutStrings.ABOUT_TESTIMONIAL_SUBTITLE1,
                                        'name': aboutStrings.ABOUT_TESTIMONIAL_NAME1,
                                        'image': photoMing,
                                        'alt_text': aboutStrings.ABOUT_TESTIMONIAL_ALT1
                                    },
                                    {
                                        'title': aboutStrings.ABOUT_TESTIMONIAL_TITLE2,
                                        'subtitle': aboutStrings.ABOUT_TESTIMONIAL_SUBTITLE2,
                                        'name': aboutStrings.ABOUT_TESTIMONIAL_NAME2,
                                        'image': photoSaloni,
                                        'alt_text': aboutStrings.ABOUT_TESTIMONIAL_ALT2
                                    },
                                    {
                                        'title': aboutStrings.ABOUT_TESTIMONIAL_TITLE3,
                                        'subtitle': aboutStrings.ABOUT_TESTIMONIAL_SUBTITLE3,
                                        'name': aboutStrings.ABOUT_TESTIMONIAL_NAME3,
                                        'image': photoKevin,
                                        'alt_text': aboutStrings.ABOUT_TESTIMONIAL_ALT3
                                    },
                                    {
                                        'title': aboutStrings.ABOUT_TESTIMONIAL_TITLE4,
                                        'subtitle': aboutStrings.ABOUT_TESTIMONIAL_SUBTITLE4,
                                        'name': aboutStrings.ABOUT_TESTIMONIAL_NAME4,
                                        'image': iconUser,
                                        'alt_text': aboutStrings.ABOUT_TESTIMONIAL_ALT4
                                    },
                                    {
                                        'title': aboutStrings.ABOUT_TESTIMONIAL_TITLE5,
                                        'subtitle': aboutStrings.ABOUT_TESTIMONIAL_SUBTITLE5,
                                        'name': aboutStrings.ABOUT_TESTIMONIAL_NAME5,
                                        'image': photoJessica,
                                        'alt_text': aboutStrings.ABOUT_TESTIMONIAL_ALT5
                                    },
                                    {
                                        'title': aboutStrings.ABOUT_TESTIMONIAL_TITLE6,
                                        'subtitle': aboutStrings.ABOUT_TESTIMONIAL_SUBTITLE6,
                                        'name': aboutStrings.ABOUT_TESTIMONIAL_NAME6,
                                        'image': photoSamita,
                                        'alt_text': aboutStrings.ABOUT_TESTIMONIAL_ALT6
                                    },
                                    {
                                        'title': aboutStrings.ABOUT_TESTIMONIAL_TITLE7,
                                        'subtitle': aboutStrings.ABOUT_TESTIMONIAL_SUBTITLE7,
                                        'name': aboutStrings.ABOUT_TESTIMONIAL_NAME7,
                                        'image': photoShaunak,
                                        'alt_text': aboutStrings.ABOUT_TESTIMONIAL_ALT7
                                    },
                                    {
                                        'title': aboutStrings.ABOUT_TESTIMONIAL_TITLE8,
                                        'subtitle': aboutStrings.ABOUT_TESTIMONIAL_SUBTITLE8,
                                        'name': aboutStrings.ABOUT_TESTIMONIAL_NAME8,
                                        'image': photoMrunmai,
                                        'alt_text': aboutStrings.ABOUT_TESTIMONIAL_ALT8
                                    },
                                    {
                                        'title': aboutStrings.ABOUT_TESTIMONIAL_TITLE9,
                                        'subtitle': aboutStrings.ABOUT_TESTIMONIAL_SUBTITLE9,
                                        'name': aboutStrings.ABOUT_TESTIMONIAL_NAME9,
                                        'image': photoNatasha,
                                        'alt_text': aboutStrings.ABOUT_TESTIMONIAL_ALT9
                                    }
                                ]
                            }
                        ]}
                    /> */}


                </div>

                <Footer />
            </div>

        </div>
    );
}

export default AboutPage;